import "./ShiftRateComponent.scss";
import React, {useState, useCallback, useEffect} from "react";
import RadioButtonGroupComponent
    from "../../../../shared/components/form-controls/radio-button-group/RadioButtonGroupComponent";
import InputComponent from "../../../../shared/components/form-controls/input/InputComponent";
import {Field, FieldProps} from "formik";
import FormikInputComponent from "../../../../shared/components/form-controls/formik-input/FormikInputComponent";
import FormikCheckBoxComponent
    from "../../../../shared/components/form-controls/formik-check-box/FormikCheckBoxComponent";

interface ShiftRateComponentProps{
    values?:any;
    setFieldValue?:any;
}

const ShiftRateComponent=(props:ShiftRateComponentProps)=>{
    const {values,setFieldValue}=props
    const [shiftType, setShiftType] = useState<string>('regular');

    const calculateTotalShiftRate = useCallback(() => {
        let total = values?.base_shift_rate; // Start with the base shift rate

        if (values?.is_pm_diff) {
            total += parseInt(values.hcp_pm_diff || 0);
        }
        if (values?.is_noc_diff) {
            total += parseInt(values.hcp_noc_diff || 0);
        }
        if (values?.is_weekend) {
            total += parseInt(values.hcp_weekend_rate || 0);
        }
        if (values?.is_rush) {
            total += parseInt(values.hcp_rush_rate || 0);
        }
        if (values?.is_hazard) {
            total += parseInt(values.hcp_hazard_rate || 0);
        }
        setFieldValue('shift_rate',total)
        return total;

    }, [values,setFieldValue]);

    useEffect(() => {
        calculateTotalShiftRate()
    }, [calculateTotalShiftRate]);

    return (
        <div className={'shift-rate-component'}>
            <div className={'shift-rate-radio-wrapper'}>
                <div>
                    <RadioButtonGroupComponent
                        options={[
                            {
                                title: 'Regular Shift',
                                code: 'regular',
                            },
                            {title: 'Holiday Shift', code: 'holiday'},
                        ]}
                        valueExtractor={(option: any) => option.code}
                        value={shiftType}
                        onChange={(value) => {
                            setShiftType(value)
                            const finalValue=value === 'holiday';
                            setFieldValue('is_holiday' , finalValue)
                        }}
                    />
                </div>
                <div className={'d-flex'}>
                    <div className={'shift-rate-input'}>
                        <Field name={'base_shift_rate'}>
                            {(field: FieldProps) => (
                                <FormikInputComponent
                                    formikField={field}
                                    maxLength={5}
                                    type={'number'}
                                    onChange={(value:any)=>{
                                        setFieldValue('base_shift_rate',value ? parseInt(value) : 0)
                                    }}
                                />
                            )}
                        </Field>
                        {/*<InputComponent*/}
                        {/*    maxLength={5}*/}
                        {/*    value={shiftRate}*/}
                        {/*    onChange={(e)=>setShiftRate(e ? parseInt(e): 0)}*/}
                        {/*/>*/}
                    </div>
                    {/*{shiftType === 'holiday' &&*/}
                    {/*    <div className={'d-flex'}>*/}
                    {/*        <div className={'shift-rate-input mrg-right-20 mrg-left-20'}>*/}
                    {/*            <InputComponent*/}
                    {/*                disabled*/}
                    {/*                value={`x ${facilityDetails?.hourly_base_rates?.holiday}`}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*        <div className={'shift-rate-input'}>*/}
                    {/*            <InputComponent*/}
                    {/*                disabled*/}
                    {/*                type={'number'}*/}
                    {/*                value={(facilityDetails?.hourly_base_rates?.holiday * shiftRate)?.toString()}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*}*/}
                </div>
            </div>
            <div
                className={
                    'shift-requirement-divider-line mrg-bottom-20 mrg-top-20'
                }
            />
            <div className={'shift-rate-radio-wrapper mrg-bottom-20'}>
                <Field name={'is_pm_diff'}>
                    {
                        (field: FieldProps) => (
                            <FormikCheckBoxComponent
                                formikField={field}
                                label={"PM"}
                                onChange={(e) => {
                                    const isChecked = e;
                                    setFieldValue('is_pm_diff', isChecked);
                                    if (!isChecked) {
                                        setFieldValue('hcp_pm_diff', 0);
                                    }
                                }}
                            />
                        )
                    }
                </Field>
                <div className={'shift-rate-input'}>
                    <Field name={'hcp_pm_diff'}>
                        {(field: FieldProps) => (
                            <FormikInputComponent
                                disabled={!values?.is_pm_diff}
                                formikField={field}
                                maxLength={5}
                                type={'number'}
                                onChange={(value:any)=>{
                                    setFieldValue('hcp_pm_diff',value ? parseInt(value) : 0)
                                }}
                            />
                        )}
                    </Field>
                </div>
            </div>
            <div className={'shift-rate-radio-wrapper mrg-bottom-20'}>
                <Field name={'is_noc_diff'}>
                    {
                        (field: FieldProps) => (
                            <FormikCheckBoxComponent
                                formikField={field}
                                label={"NOC"}
                                onChange={(e) => {
                                    const isChecked = e;
                                    setFieldValue('is_noc_diff', isChecked);
                                    if (!isChecked) {
                                        setFieldValue('hcp_noc_diff', 0);
                                    }
                                }}
                            />
                        )
                    }
                </Field>
                <div className={'shift-rate-input'}>
                    <Field name={'hcp_noc_diff'}>
                        {(field: FieldProps) => (
                            <FormikInputComponent
                                disabled={!values?.is_noc_diff}
                                formikField={field}
                                type={'number'}
                                maxLength={5}
                                onChange={(value:any)=>{
                                    setFieldValue('hcp_noc_diff',value ? parseInt(value) : 0)
                                }}
                            />
                        )}
                    </Field>
                </div>
            </div>
            <div className={'shift-rate-radio-wrapper'}>
                <Field name={'is_weekend'}>
                    {
                        (field: FieldProps) => (
                            <FormikCheckBoxComponent
                                formikField={field}
                                label={"Weekend"}
                                onChange={(e) => {
                                    const isChecked = e;
                                    setFieldValue('is_weekend', isChecked);
                                    if (!isChecked) {
                                        setFieldValue('hcp_weekend_rate', 0);
                                    }
                                }}
                            />
                        )
                    }
                </Field>
                <div className={'shift-rate-input'}>
                    <Field name={'hcp_weekend_rate'}>
                        {(field: FieldProps) => (
                            <FormikInputComponent
                                disabled={!values?.is_weekend}
                                formikField={field}
                                type={'number'}
                                maxLength={5}
                                onChange={(value:any)=>{
                                    setFieldValue('hcp_weekend_rate',value ? parseInt(value) : 0)
                                }}
                            />
                        )}
                    </Field>
                </div>
            </div>
            <div
                className={
                    'shift-requirement-divider-line mrg-bottom-20 mrg-top-20'
                }
            />
            <div className={'shift-rate-radio-wrapper'}>
                <Field name={'is_rush'}>
                    {
                        (field: FieldProps) => (
                            <FormikCheckBoxComponent
                                formikField={field}
                                label={"Rush Rate"}
                                onChange={(e) => {
                                    const isChecked = e;
                                    setFieldValue('is_rush', isChecked);
                                    if (!isChecked) {
                                        setFieldValue('hcp_rush_rate', 0);
                                    }
                                }}
                            />
                        )
                    }
                </Field>
                <div className={'shift-rate-input mrg-bottom-20'}>
                    <Field name={'hcp_rush_rate'}>
                        {(field: FieldProps) => (
                            <FormikInputComponent
                                disabled={!values?.is_rush}
                                formikField={field}
                                type={'number'}
                                maxLength={5}
                                onChange={(value:any)=>{
                                    setFieldValue('hcp_rush_rate',value ? parseInt(value) : 0)
                                }}
                            />
                        )}
                    </Field>
                </div>
            </div>
            <div className={'shift-rate-radio-wrapper'}>
                <Field name={'is_hazard'}>
                    {
                        (field: FieldProps) => (
                            <FormikCheckBoxComponent
                                formikField={field}
                                label={"Hazard Rate"}
                                onChange={(e) => {
                                    const isChecked = e;
                                    setFieldValue('is_hazard', isChecked);
                                    if (!isChecked) {
                                        setFieldValue('hcp_hazard_rate', 0);
                                    }
                                }}
                            />
                        )
                    }
                </Field>
                <div className={'shift-rate-input'}>
                    <Field name={'hcp_hazard_rate'}>
                        {(field: FieldProps) => (
                            <FormikInputComponent
                                disabled={!values?.is_hazard}
                                formikField={field}
                                type={'number'}
                                maxLength={5}
                                onChange={(value:any)=>{
                                    setFieldValue('hcp_hazard_rate',value ? parseInt(value) : 0)
                                }}
                            />
                        )}
                    </Field>
                </div>
            </div>
            <div
                className={
                    'shift-requirement-divider-line mrg-bottom-20 mrg-top-20'
                }
            />
            <div className={'shift-rate-radio-wrapper'}>
                <div className={'shift-rate-text'}>Final Shift rate</div>
                <div className={'shift-rate-input'}>
                    <InputComponent
                        disabled={true}
                        value={values?.shift_rate || 0}
                    />
                </div>
            </div>
        </div>
    )
}

export default ShiftRateComponent